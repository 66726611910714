<template>
  <div class="search">
    <div class="search-header__wrapper">
      <div class="container">
        <div class="search-header">
          <div class="search-header__input">
            <input
              type="text"
              class="input input--grey"
              v-model.lazy="query"
              autofocus
            />
            <div class="search-header__input-icon">
              <svg class="icon icon-search ">
                <use xlink:href="#search"></use>
              </svg>
            </div>
            <button
              class="search-header__input-button"
              @click.prevent="this.query = ''"
            >
              <svg class="icon icon-cross ">
                <use xlink:href="#cross"></use>
              </svg>
            </button>
          </div>
          <button class="search-header__decline" @click.prevent="cancel()">
            Отмена
          </button>
        </div>
      </div>
    </div>
    <div class="search-suggestions__wrapper">
      <div class="container">
        <div class="search-cards">
          <template v-if="products.length > 0">
            <div class="catalog-card" v-for="product in products" :key="product.id">
              <Product :product="product" :classItem="'product-card--big'"/>
            </div>
            <img
              src="/img/hourglass.svg"
              class="catalog-preloader"
              v-if="!isLastPage"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "@/components/cards/product";

export default {
  components: {
    Product,
  },

  data() {
    return {
      query: '',
    };
  },

  created() {
    if (this.$route.params.query && this.$route.params.page) {
      this.query = this.$route.params.query;
      this.search(this.query, this.$route.params.page);
    }
  },

  watch: {
    query() {
      this.search(this.query, 1);
    }
  },

  mounted() {
    const cards = document.querySelector(".search-cards");

    if (cards) {
      cards.addEventListener("scroll", () => {
        const top = cards.scrollTop + cards.clientHeight;
        const bottom = cards.scrollHeight;

        if (top + 100 >= bottom && !this.isLoad && !this.isLastPage) {
          this.loadProducts();
        }
      });
    }
  },

  computed: {
    products() {
      return this.$store.state.search.products;
    },

    pageCount() {
      return this.$store.state.search.pageCount;
    },

    pageCurrent() {
      return this.$store.state.search.pageCurrent;
    },

    isLastPage() {
      return this.$store.state.search.isLastPage;
    },

    isLoad() {
      return this.$store.state.search.isLoad;
    },
  },

  methods: {
    cancel() {
      this.$router.push("/catalog/menu");
    },

    search(query, page) {
      this.$store
        .dispatch("SEARCH_FETCH_DATA", {
          QUERY: query,
          PAGE: page,
        });
    },

    loadProducts() {
      this.$store
        .dispatch("SEARCH_PAGINATION", {
          QUERY: this.query,
          PAGE: this.pageCurrent + 1,
        });
    },
  },
};
</script>
